<template>
  <div>
    <div>
      <h3 class="mb-5 mt-10">
        {{ $t("YourProfile") }}
      </h3>
    </div>
    <vx-card no-shadow class="pt-4">
      <!-- Img Row -->
      <div class="flex-wrap items-center mb-base hidden">
        <vs-avatar
          :src="activeUserInfo.profilePic"
          size="70px"
          class="mr-4 mb-4"
        />
        <div>
          <vs-button class="mr-4 sm:mb-0 mb-2"
            >{{ $t("Upload") }} {{ $t("Photo") }}</vs-button
          >
          <vs-button type="border" color="danger">{{ $t("Remove") }}</vs-button>
          <p class="text-sm mt-2">(JPG, GIF, PNG. Max 800kB)</p>
        </div>
      </div>

      <!-- Info -->
      <div class="mb-2">
        <label class="text-sm">{{ $t("Name") }}</label>
        <vs-input class="w-full" v-model="name"></vs-input>
      </div>
      <div class="mb-2">
        <label class="text-sm">{{ $t("Email") }}</label>
        <vs-input class="w-full" v-model="email" disabled></vs-input>
      </div>
      <!-- Phone Number -->
      <div class="mb-2">
        <label class="text-sm">{{ $t("Phone") }}</label>
        <vs-input class="w-full" type="text" v-model="phone" />
      </div>
      <!-- Languages -->
      <div class="mt-3">
        <label class="text-sm">{{ $t("Language") }}</label>
        <vs-select name="language" v-model="lang" class="w-full">
          <vs-select-item
            :key="index"
            :value="item.value"
            :text="item.text"
            v-for="(item, index) in langOptions"
          />
        </vs-select>
      </div>
      <div class="mt-3" v-if="this.operatorQueue">
        <vs-divider position="left" color="primary" class="mb-1">
          {{ $t("LabelDistributionQueue") }}
        </vs-divider>
        <div class="ml-5">
          <p>
            Utilize este recurso para habilitar e desabilitar a distribuição
            automática (rodízio). Desabilite caso esteja ausente para receber
            atendimentos e ative caso esteja apto para receber atendimentos.
          </p>
        </div>
        <vs-switch class="mt-3" color="#28c76f" v-model="inQueue" />
      </div>
      <div class="flex flex-wrap items-center justify-end">
        <vs-button class="mt-6" color="success" @click="submitForm">{{
          $t("Save")
        }}</vs-button>
      </div>
    </vx-card>
    <UserSettingsNotifications />
    <user-settings-change-password class="mt-6" />
  </div>
</template>

<script>
import axios from "@/axios";
import UserSettingsChangePassword from "./UserSettingsChangePassword.vue";
import UserSettingsNotifications from "./UserSettingsNotifications.vue";
export default {
  components: {
    UserSettingsChangePassword,
    UserSettingsNotifications,
  },
  data() {
    return {
      name: this.$store.state.user.name,
      email: this.$store.state.user.email,
      lang: this.$store.state.user.lang,
      phone: this.$store.state.user.phone,
      inQueue: false,
      operatorQueue: false,
      langOptions: [
        { text: "English", value: "en" },
        { text: "Português", value: "pt_BR" },
      ],
    };
  },
  computed: {
    activeUserInfo() {
      return this.$store.state.user;
    },
  },
  async beforeMount() {
    const inbox = await axios.get(
      `/p/chat/inbox/${this.$store.state.acc.current_acc.id}`,
      { params: { acc: this.$store.state.acc.current_acc.id } }
    );

    if (inbox.data.data.inbox.operatorQueue) {
      const operatorQueued = await axios.get(
        `/p/chat/operator/${this.$store.state.acc.current_acc.id}/${this.$store.state.user._id}`
      );
      this.inQueue = operatorQueued.data.data.inQueue;
      this.operatorQueue = inbox.data.data.inbox.operatorQueue;
    }
  },
  methods: {
    submitForm() {
      this.$http
        .put("/g/user/" + this.$store.state.user._id, {
          name: this.name,
          lang: this.lang,
          phone: this.phone,
          checkInQueue: this.operatorQueue,
          inQueue: this.inQueue,
          acc: this.$store.state.acc.current_acc.id,
        })
        .then((response) => {
          if (response.data.status === 1) {
            this.$store.commit("UPDATE_USER_INFO", response.data.data, {
              root: true,
            });

            this.$vs.notify({
              color: "success",
              title: this.$t("Success"),
              text: this.$t("InformationChanged"),
              position: "top-right",
              time: 4000,
            });

            var language = response.data.data.lang;
            if (this.$i18n.availableLocales.indexOf(language))
              this.$i18n.locale = language;
            else this.$i18n.locale = "en";

            this.$validator.localize(language);
          }
        })
        .catch((error) => {
          this.$vs.notify({
            title: this.$t("UnexpectedError"),
            text: error || error.response.data.message,
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "warning",
            position: "top-right",
            time: 4000,
          });
        });
    },
  },
};
</script>
