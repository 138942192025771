<template>
  <vx-card no-shadow>
    <vs-divider position="left" color="primary" class="mb-1">
      {{ $t("Notifications") }}
    </vs-divider>
    <div class="ml-5">
      <p>{{ $t("NotifyOptions") }}.</p>

      <div>
        <div v-for="opt in options" :key="opt.title" class="m-4">
          <div v-if="!opt.adminLevel || currentOperator.acl == 'admin'">
            <p class="tracking-wider">{{ opt.title }}</p>
            <button ref="btnfor"></button>
            <div class="flex">
              <vx-tooltip :text="$t('Phone')" color="primary">
                <vs-button
                  @click="toggle(() => (opt.phone = !opt.phone))"
                  :type="opt.phone ? 'filled' : 'border'"
                  icon-pack="feather"
                  icon="icon-smartphone"
                >
                </vs-button>
              </vx-tooltip>
              <vx-tooltip text="Email" color="primary">
                <vs-button
                  @click="toggle(() => (opt.email = !opt.email))"
                  class="ml-1"
                  :type="opt.email ? 'filled' : 'border'"
                  icon-pack="feather"
                  icon="icon-mail"
                >
                </vs-button>
              </vx-tooltip>
            </div>
          </div>
        </div>
      </div>
    </div>
  </vx-card>
</template>

<script>
export default {
  data() {
    return {
      currentOperator: { acl: false },
      options: [
        {
          name: "new message",
          title: this.$t("NotifyMessage"),
          phone: false,
          email: false,
          adminLevel: false,
        },
        {
          name: "transfer",
          title: this.$t("NotifyTransfer"),
          phone: false,
          email: false,
          adminLevel: false,
        },
        {
          name: "survey",
          title: this.$t("NotifySurveyResponse"),
          phone: false,
          email: false,
          adminLevel: true,
        },
      ],
    };
  },
  async created() {
    await this.getOperatorData();

    if (!this.currentOperator.notifications) {
      await this.$http.post("/g/user/notification/preferences", {
        acc: this.$store.state.acc.current_acc.id,
        notifications: null,
      });
      this.getOperatorData();
    }
  },
  methods: {
    async setNotifications() {
      let notifications = [];

      this.options.map((opt) => {
        notifications[opt.name + " phone"] = opt.phone;
        notifications[opt.name + " email"] = opt.email;
      });
      await this.$http.post("/g/user/notification/preferences", {
        acc: this.$store.state.acc.current_acc.id,
        notifications: { ...notifications },
      });
    },
    async getOperatorData() {
      this.currentOperator = (
        await this.$http.get(
          `/p/chat/inbox/${this.$store.state.acc.current_acc.id}`,
          {
            params: { acc: this.$store.state.acc.current_acc.id },
          }
        )
      ).data.data.operator;

      const notifications = this.currentOperator.notifications;

      if (notifications) {
        this.options = [
          {
            name: "new message",
            title: this.$t("NotifyMessage"),
            phone: notifications["new message phone"],
            email: notifications["new message email"],
            adminLevel: false,
          },
          {
            name: "transfer",
            title: this.$t("NotifyTransfer"),
            phone: notifications["transfer phone"],
            email: notifications["transfer email"],
            adminLevel: false,
          },
          {
            name: "survey",
            title: this.$t("NotifySurveyResponse"),
            phone: notifications["survey phone"],
            email: notifications["survey email"],
            adminLevel: true,
          },
        ];
      }
    },
    toggle(callback) {
      if (callback != undefined) {
        callback();
      }

      this.setNotifications();

      setTimeout(() => {
        document.activeElement.blur();
      }, 100);
    },
  },
};
</script>

<style></style>
