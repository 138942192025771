<template>
  <vx-card no-shadow>
    <vs-divider position="left" color="primary" class="mb-6">
      {{ $t("ChangePassword") }}
    </vs-divider>
    <div class="vx-col w-full">
      <vs-input
        data-vv-validate-on="blur"
        v-validate="'required'"
        class="w-full"
        type="password"
        :label-placeholder="$t('CurrentPassword')"
        v-model="old_password"
        name="old_password"
      />
      <span class="text-danger text-sm">{{
        errors.first("old_password")
      }}</span>
    </div>
    <div class="vx-col w-full">
      <vs-input
        id="password"
        ref="password"
        data-vv-validate-on="blur"
        v-validate="'required|min:4'"
        name="new_password"
        iconPack="feather"
        icon="icon-eye show_password_icon"
        icon-after="true"
        class="w-full"
        type="password"
        :label-placeholder="$t('NewPassword')"
        v-model="new_password"
      />
      <span class="text-danger text-sm">{{
        errors.first("new_password")
      }}</span>
    </div>
    <div class="vx-col w-full">
      <vs-input
        name="confirm_new_password"
        v-validate="'required|min:4|confirmed:password'"
        data-vv-validate-on="blur"
        data-vv-as="password"
        class="w-full"
        type="password"
        :label-placeholder="$t('ConfirmNewPassword')"
        v-model="confirm_new_password"
      />
      <span class="text-danger text-sm">{{
        errors.first("confirm_new_password")
      }}</span>
    </div>
    <!-- Save & Reset Button -->
    <div class="flex flex-wrap items-center justify-end">
      <vs-button
        class="mt-6"
        color="success"
        :disabled="!validateForm"
        @click="submitForm"
        >{{ $t("Save") }}</vs-button
      >
    </div>
  </vx-card>
</template>

<script>
export default {
  data() {
    return {
      old_password: "",
      new_password: "",
      confirm_new_password: "",
    };
  },
  computed: {
    validateForm() {
      return (
        this.old_password != "" &&
        this.new_password != "" &&
        this.confirm_new_password != ""
      );
    },
    activeUserInfo() {
      return this.$store.state.user;
    },
  },
  methods: {
    submitForm() {
      this.$http
        .post("/g/user/change-password/" + this.$store.state.user._id, {
          old_password: this.old_password,
          new_password: this.new_password,
        })
        .then((response) => {
          if (response.data.status === 1) {
            this.old_password = "";
            this.new_password = "";
            this.confirm_new_password = "";

            this.$vs.notify({
              color: "success",
              title: this.$t("Success"),
              text: this.$t("PasswordChanged"),
              position: "top-right",
              time: 4000,
            });
          }
        })
        .catch((error) => {
          this.$vs.notify({
            title: this.$t("UnexpectedError"),
            text: error.response.data.message,
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "warning",
            position: "top-right",
            time: 4000,
          });
        });
    },
  },
  mounted() {
    var passwordRevealElement =
      document.getElementsByClassName("show_password_icon");
    if (passwordRevealElement.length > 0)
      passwordRevealElement[0].addEventListener("click", function () {
        var classList = passwordRevealElement[0].getAttribute("class");
        if (classList.search("icon-eye-off") !== -1)
          passwordRevealElement[0].classList = classList.replace(
            "icon-eye-off",
            "icon-eye"
          );
        else
          passwordRevealElement[0].classList = classList.replace(
            "icon-eye",
            "icon-eye-off"
          );
        var input = document.getElementById("password");
        if (input.getAttribute("type") == "password") {
          input.setAttribute("type", "text");
        } else {
          input.setAttribute("type", "password");
        }
      });
  },
};
</script>
